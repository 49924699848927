import React from 'react';
import styled from "styled-components";
import { Section, Container, HeaderMainText } from '@components/global';
import ExternalLink from '@common/ExternalLink';


const Testimonials  = () => (
    <Section id='testimonials'>
    <TestimonialWrapper>
        <Container>
            <AboutDetailsEliteCleaning>
                <HeaderMainText>Testimonials</HeaderMainText>
            </AboutDetailsEliteCleaning>
            <Grid>
                <div>
                    <BlockquoteSmallText>
                        <q>
                            Smith Property Management have been working with Elite Cleaning Services for nearly 11 years and
                            we are pleased and can confidently endorse them as an outstanding business partner. Since our
                            earliest involvement with Elite Cleaning Services, they have been a solid and reliable service
                            provider. Elite Cleaning Services representation of Smith Property Management to our mutual
                            customer base has been exemplary. We have always been able to rely on their responsiveness,
                            flexibility, and courteous service. In addition, they continue to give value for services
                            provided. Above all the Elite Cleaning team have always been professional in the manner, in
                            which they conduct their business. We look forward too many more years of cooperation and a
                            continuing business relationship with Peter and the team at Elite Cleaning Services.
                        </q>
                        <cite>
                            <strong>Barbara Smith MD </strong>
                            <ExternalLink href="https://www.spm.ie/">Smith Property Management</ExternalLink>
                        </cite>
                    </BlockquoteSmallText>
                </div>
                <div>
                    <BlockquoteStyle>
                        <q>
                            Elite Cleaning Services Ltd provides Nua Healthcare services with a range of services as
                            follows on a regular basis:
                            <ul>
                                <li>Construction builders clean</li>
                                <li>Residential cleaning (on request)</li>
                                <li>Deep cleaning / carpet cleaning /steam cleaning service.</li>
                            </ul>
                            I would have no hesitation recommending Peter & Elite Cleaning services.
                        </q>
                        <cite>
                            <strong>Tommy O'Sullivan - Building/Facilities Manager</strong>
                            <ExternalLink href="https://www.nuahealthcare.ie/">Nua Healthcare</ExternalLink>
                        </cite>
                    </BlockquoteStyle>
                </div>

                <div>

                    <BlockquoteStyle>
                        <q>Slane Castle Ltd appointed Elite Cleaning Services as our official cleaning supplier in early
                            2016. We have been very happy with the thorough job Peter’s team provide on a regular basis
                            at the Castle and as you can imagine it is a large property with lots of areas and the team
                            always ensure to complete the cleaning list provided to them. We would have no hesitation in
                            recommending Elite Cleaning Services.</q>
                        <cite>
                            <strong>Rhonda De Paor - Manager</strong>
                            <ExternalLink href="https://www.slanecastle.ie/">Slane Castle</ExternalLink>
                        </cite>
                    </BlockquoteStyle>

                </div>

            </Grid>
        </Container>
        </TestimonialWrapper>
    </Section>
);
const TestimonialWrapper = styled.header`
  background: rgb(233,246,252, 0.5);
`;
var BlockquoteStyle = styled.blockquote`
  background-color: white;
  
`;

const BlockquoteSmallText = styled.blockquote`
  background-color: white;
  line-height: 1.3em;
  
  font-size: 15px;
  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 13px;
    line-height: 1.1em;
  }
`;

const AboutDetailsEliteCleaning = styled.div`
  justify-self: center;

  text-align: center;
  font-size: 50px;
  
  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 20px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 6rem;
  align-items: center;
  padding: 5px;
  margin: 24px 0;
  line-height: 1.5em;
  strong {
    display: block;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-column-gap: 3em;
    grid-row-gap: 2em
  }
`;

export default Testimonials;