import React from 'react';
import { Container, HeaderSubMainText, HeaderMainText, HeaderWrapper, Text } from '@components/global';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Header = () => (

    <Container>
        <HeaderWrapper>
        <Text>
          <HeaderMainText>
            Professional and Cost effective Cleaning Services
          </HeaderMainText>
          <HeaderSubMainText style={{'background-color': 'white'}}>We are a family owned, full-service cleaning company based in Bettystown, Co. Meath.
          We've been serving local and regional commercial businesses since 2007.</HeaderSubMainText>

          <AnchorLink href='#contact' className='btn'>
            Contact Us Today
          </AnchorLink>
        </Text>
        </HeaderWrapper>
    </Container>

);





export default Header;
