import React from 'react';
import styled from 'styled-components';
import ExternalLink from '@common/ExternalLink';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Section, Container, HeaderSubMainText, HeaderMainText } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        client_dps: file(
          sourceInstanceName: { eq: "clients" }
          name: { eq: "dps_property_facility_management" }
        ) {
          childImageSharp {
            fixed(width: 180, height: 84) { 
              ...GatsbyImageSharpFixed
            }
          }
        }
        client_smith_property_managers: file(
          sourceInstanceName: { eq: "clients" }
          name: { eq: "smith_property_management" }
        ) {
          childImageSharp {
            fixed(width: 180, height: 112) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        slane_castle: file(
          sourceInstanceName: { eq: "clients" }
          name: { eq: "slane_castle" }
        ) {
          childImageSharp {
            fixed(width: 140, height: 58) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        nabco_coop_living: file(
          sourceInstanceName: { eq: "clients" }
          name: { eq: "nabco_coop_living" }
        ) {
          childImageSharp {
            fixed(width: 140, height: 58) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        nua_logo: file(
          sourceInstanceName: { eq: "clients" }
          name: { eq: "nua-logo" }
        ) {
          childImageSharp {
            fixed(width: 200, height: 83) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="about">
        <AlternateBackground>
        <Container>
          <SectionHeader>
            <HeaderMainText>About Elite Cleaning Services</HeaderMainText>
            <HeaderSubMainText style={{'background-color':'white'}}>We're a family owned, full-service cleaning company based in Bettystown, Co. Meath.
              Established in 2007, Elite Cleaning Services has been trading in the County Louth, Meath and Dublin areas serving the comprehensive cleaning requirements of local and regional commercial businesses.
              Coupled with a professional and friendly service, we can look after all your cleaning needs with minimum fuss.

            </HeaderSubMainText>
          </SectionHeader>
          <SectionHeader>
            <HeaderMainText>Companies we've been privileged to work with</HeaderMainText>
          </SectionHeader>
          <Grid>
            <Customer>
              <ExternalLink href="https://spm.ie/">
                <Art>
                  <Img alt="Elite Cleaning Services client : Smith Property Management" fixed={data.client_smith_property_managers.childImageSharp.fixed} />
                </Art>

                <p>
                  Smith Property Management
                </p>
              </ExternalLink>
            </Customer>
            <Customer>
              <ExternalLink href="https://www.nuahealthcare.ie/">
                <Art>
                  <Img alt="Elite Cleaning Services client: Nua Healthcare" fixed={data.nua_logo.childImageSharp.fixed} />
                </Art>
                <p>
                  Nua Healthcare
                </p>
              </ExternalLink>
            </Customer>


            <Customer>
              <ExternalLink href="https://www.slanecastle.ie/">
                <Art>
                <Img alt="Elite Cleaning Services client: Slane Castle" fixed={data.slane_castle.childImageSharp.fixed} />
                </Art>
                <p>
                Slane Castle
                </p>
              </ExternalLink>

            </Customer>
            <Customer>
              <ExternalLink href="http://www.nabco.ie/">
                <Art>
                  <Img alt="Elite Cleaning Services client: Nabco Co-operative Living" fixed={data.nabco_coop_living.childImageSharp.fixed} />
                </Art>
                <p>
                  Nabco Co-operative Living
                </p>
              </ExternalLink>
            </Customer>
          </Grid>


        </Container>
        </AlternateBackground>
      </Section>
    )}
  />
);
const SectionHeader = styled.div`
  text-align: center;
  font-size: 50px;
  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 20px;
  }
`;
const Customer = styled.div`
  background-color: white;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 4fr 4fr;
  grid-gap: 40px;
  text-align: center;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: center;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }
  }
`;

const AlternateBackground = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
`;
const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
  
`;

export default About;
