import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import About from '@sections/About';
import Contact from '@sections/Contact';
import Footer from '@sections/Footer';
import Services from '@sections/Services';
import Testimonials from "../components/sections/Testimonials";

const IndexPage = () => (
  <Layout>
    <Navbar />
    <Header />
    <Services />
    <Testimonials />
    <About />

    <Contact />
    <Footer />
  </Layout>
);

export default IndexPage;
