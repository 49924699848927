import React from 'react';

import { Section, Container } from '@components/global';

import styled from "styled-components";


const Contact = () => (
  <Section id="contact">
    <Container>
        <ContactDetailsEliteCleaning>
            <h2>Get in touch with Elite Cleaning</h2>
            <FlexColumn>
                <Spacing>
                    <a href="tel:3530879238090">+353 087 9238090</a>
                </Spacing>
                <Spacing>
                    <a href="mailto:peter@elitecleaningservices.ie">peter @ elitecleaningservices.ie</a>
                </Spacing>
            </FlexColumn>
        </ContactDetailsEliteCleaning>
    </Container>
  </Section>
);
const ContactDetailsEliteCleaning = styled.div`
  background: rgb(233,246,252, 0.5);
  padding: 20px;
  text-align: center;
  font-size: 50px;
  height: 50vh;
  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 18px;
  }
`;

const FlexColumn = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-content: space-around; 
  justify-items: center;
  .phone, .email {
    
  }
  
`;
const Spacing = styled.div`
    margin-bottom: 16px;
`;
export default Contact;
