import React from 'react';
import styled from "styled-components";


import { Section, Container, HeaderMainText } from '@components/global';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '@styles/react-tabs.css';

const Services  = () => (
    <Section id="services">
        <AlternateBackground>
        <Container>
            <ContactDetailsEliteCleaning>
                <HeaderMainText>Our Services</HeaderMainText>
            </ContactDetailsEliteCleaning>
            <Tabs>
                <TabList>
                    <Tab>COVID-19 Cleaning</Tab>
                    <Tab>Facility Management</Tab>
                    <Tab>Builders or Tenant Clean</Tab>
                    <Tab>Window Cleaning</Tab>
                    <Tab>Gutter Cleaning</Tab>
                    <Tab>Power Washing</Tab>
                </TabList>
                <TabPanel >
                    <Holder>
                        <TextHolder>
                            <h3>COVID-19 Cleaning</h3>
                            <p>Elite Cleaning Services can disinfect your workspace helping to keep it safe for both employees and customers during the ongoing COVID-19 crisis.</p>
                            <p>All of our services are carried out by qualified professionals are adhere to strict HSE National Cleaning Standards.</p>
                        </TextHolder>
                        <ServiceHighlights>
                            <span className="title">service highlights</span>
                            <ul className="list">
                                <li>Deep clean/sanitation </li>
                                <li>High touch point focused</li>
                                <li>Hospital Grade disinfectants used</li>
                                <li>Strict Cross-Contamination procedures</li>
                            </ul>
                        </ServiceHighlights>
                    </Holder>

                </TabPanel>
                    <TabPanel >
                        <Holder>
                            <TextHolder>
                                <h3>Facility Management</h3>
                                <p>We currently provide our cleaning services to over 20 sites throughout Leinster. From
                                    weekly cleaning of residential apartment blocks to quarterly external cleaning, this
                                    service will assure all residents that hygiene levels are a top priority.</p>
                            </TextHolder>
                            <ServiceHighlights>
                                <span className="title">service highlights</span>
                                <ul className="list">
                                    <li>Commercial &amp; Domestic</li>
                                    <li>No quibble return policy</li>
                                    <li>Premium Customer Service</li>
                                    <li>Trust us to do the dirty work!</li>
                                </ul>
                            </ServiceHighlights>
                        </Holder>

                    </TabPanel>
                    <TabPanel  >

                        <Holder>
                            <TextHolder>
                                <h3>Builders or Tenant Clean</h3>
                                <p>All members of elite staff are safe pass trained and certified in each aspect of
                                    cleaning that is required in today's building environment. This cleaning service
                                    will ensure that the hand-over process to the new client will be convenient to both
                                    the the developer and the client.</p>
                                <p>Our tenant clean provides peace of mind to any landlord. Ensuring the minimum amount
                                    of time between transition from Tenant to Tenant. Helping the premises to maximise
                                    the return potential for landlords.</p>
                            </TextHolder>
                            <ServiceHighlights>
                                <span className="title">service highlights</span>
                                <ul className="list">
                                    <li>Commercial &amp; Domestic</li>
                                    <li>No quibble return policy</li>
                                    <li>Premium Customer Service</li>
                                    <li>Trust us to do the dirty work!</li>
                                </ul>
                            </ServiceHighlights>
                        </Holder>
                    </TabPanel>
                    <TabPanel >

                        <Holder>
                            <TextHolder>
                                <h3>Window Cleaning</h3>
                                <p>We can offer traditional window cleaning services, combined with our
                                    Reach &amp; Clean system, enabling us to access access those inaccessible windows up
                                    to 40 ft. All our staff are fully trained and can guarantee the best results for our
                                    commerical and domestic customers.</p>
                                <p>We also have a certified boom operator licence that will enable window crews to reach
                                    up to 8 stories if required.</p>
                            </TextHolder>
                            <ServiceHighlights>
                                <span className="title">service highlights</span>
                                <ul className="list">
                                    <li>Commercial &amp; Domestic</li>
                                    <li>No quibble return policy</li>
                                    <li>Premium Customer Service</li>
                                    <li>Trust us to do the dirty work!</li>
                                </ul>
                            </ServiceHighlights>
                        </Holder>
                    </TabPanel>
                    <TabPanel >

                        <Holder>
                            <TextHolder>
                                <h3>Gutter Cleaning</h3>
                                <p>Our gutter cleaning service offers great value for money with internal / external cleaning of the gutter / soffits and facias.
                                    Clearing all downpipes to ensure free flow of rain water and reduce the risk of expensive repairs.</p>
                            </TextHolder>
                            <ServiceHighlights>
                                <span className="title">service highlights</span>
                                <ul className="list">
                                    <li>Commercial &amp; Domestic</li>
                                    <li>No quibble return policy</li>
                                    <li>Premium Customer Service</li>
                                    <li>Trust us to do the dirty work!</li>
                                </ul>
                            </ServiceHighlights>
                        </Holder>

                    </TabPanel>
                    <TabPanel >

                        <Holder>
                            <TextHolder>
                                <h3>Power Washing</h3>
                                <p>Our power washer machines are 3000 psi and have the capacity to remove the most
                                    stubborn of stains from any surface.</p>
                                <p>In addition to driveways or patios and deck areas, we can also remove unwanted
                                    graffiti to restore your home or business back to new.</p>
                            </TextHolder>
                            <ServiceHighlights>
                                <span className="title">service highlights</span>
                                <ul className="list">
                                    <li>Commercial &amp; Domestic</li>
                                    <li>No quibble return policy</li>
                                    <li>Premium Customer Service</li>
                                    <li>Trust us to do the dirty work!</li>
                                </ul>
                            </ServiceHighlights>
                        </Holder>

                    </TabPanel>

            </Tabs>
        </Container>
        </AlternateBackground>
    </Section>
);
const AlternateBackground = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  @media (max-width: ${props => props.theme.screen.sm}) {
    .react-tabs {
        font-size: 16px;

        p {
            font-size: 14px;
            line-height: 25px;
        }
    }
  }
`;
const ContactDetailsEliteCleaning = styled.div`
  text-align: center;
  font-size: 50px;
  
  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 18px;
    
  }
`;
const Holder = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 12rem;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-row-gap: 3rem;
  }
`;
const TextHolder = styled.div`
  background-color: white;
`;
const ServiceHighlights = styled.div`
  background: #ebf6fb;
  width: auto;
  border: 1px solid #e6e9ef;
  padding: 27px 10px 45px 10px;
  
  .title {
    line-height: 15px;
    color: #323232;
    text-transform: uppercase;
    border-bottom: 1px solid #cfd5e0;
    padding: 0 12px 18px 5px;
    display: block;
    text-align: center;
    margin: 0 0 14px;
    letter-spacing: 2px;
  }
  .list {
    list-style: none;
    margin-left: 5px;
    margin-right: 0px;
    padding-left: 10px;
    padding-right: 0px;


  }
  .list li:before {
      content:'\\2713';
      font-weight:bold;
      display:inline-block;
      vertical-align: top;
      line-height: 1em;
      width: 1em;
      height:1em;
      margin-right: 0.3em;
      text-align: center;
      color: blue;
      padding: 2px
  }
`;
export default Services;